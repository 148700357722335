// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://djrqfalozbemxli43dxt3otd5i.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_content_delivery_bucket": "compcloset-20190510131254-hostingbucket-qa-uat",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://dzmjgbbtwfmmn.cloudfront.net"
};


export default awsmobile;
